import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import { GatsbyProps } from "Src/global-types"

import Card, { CardImage } from "Components/Card"
import StandardLayout from "Components/core/StandardLayout"
import SEO from "Components/core/SEO"

export const CardFlex = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0 0 0;
`

export interface TinkeringData {
  firstReact: CardImage
  playingWithHexagons: CardImage
  hexagonsV2: CardImage
  hexout: CardImage
  a11yResource: CardImage
  yahtzeeRedux: CardImage
}
interface TinkeringProps {
  data: TinkeringData
}

const Tinkering: React.FC<TinkeringProps & GatsbyProps> = ({
  location,
  data,
}) => {
  return (
    <StandardLayout location={location}>
      <SEO title="Tinkering Projects" />
      <h1>Tinkering</h1>
      <CardFlex>
        <Card imageFile={data.firstReact} title="My First React Project">
          <Link
            className="multiline"
            to="/projects/tinkering/first-react-project/"
          >
            Checkout my first react project
          </Link>
        </Card>
        <Card
          imageFile={data.playingWithHexagons}
          title="Playing With Hexagons"
        >
          <Link
            className="multiline"
            to="/projects/tinkering/playing-with-hexagons/"
          >
            Checkout me playing with hexagons
          </Link>
        </Card>
        <Card imageFile={data.hexagonsV2} title="Playing with Hexagons V2">
          <Link className="multiline" to="/projects/tinkering/hexagons-v2/">
            Checkout my second version of playing with hexagons
          </Link>
        </Card>
        <Card imageFile={data.hexout} title="Hexout!">
          <Link className="multiline" to="/projects/tinkering/hexout/">
            Checkout out my breakout clone!
          </Link>
        </Card>
        <Card imageFile={data.a11yResource} title="A11y Resources">
          <Link className="multiline" to="/projects/tinkering/a11y-resources/">
            Checkout out my a11y resources
          </Link>
        </Card>
        <Card imageFile={data.yahtzeeRedux} title="Yahtzee Redux">
          <Link className="multiline" to="/projects/tinkering/yahtzee-redux/">
            Checkout my second attempt at yahtzee
          </Link>
        </Card>
      </CardFlex>
    </StandardLayout>
  )
}
export const query = graphql`
  query {
    firstReact: file(relativePath: { glob: "project-thumbnails/steam*" }) {
      ...CardImage
    }
    playingWithHexagons: file(
      relativePath: { glob: "project-thumbnails/playing*" }
    ) {
      ...CardImage
    }
    hexagonsV2: file(
      relativePath: { glob: "project-thumbnails/hexagons-v2*" }
    ) {
      ...CardImage
    }
    hexout: file(relativePath: { glob: "project-thumbnails/hexout*" }) {
      ...CardImage
    }
    a11yResource: file(relativePath: { glob: "project-thumbnails/a11y*" }) {
      ...CardImage
    }
    yahtzeeRedux: file(relativePath: { glob: "project-thumbnails/yahtzee*" }) {
      ...CardImage
    }
  }
`

export default Tinkering
